import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { ZoneStatus, SpaceStatus } from "../../interfaces";
import { isZeroDate } from "../../dates";
import TreeContext from "../../TreeContext";

interface Props {
  status: ZoneStatus;
  spaceId: number;
}

const ZoneStatusIndicator: React.FC<Props> = ({ status, spaceId }) => {
  const { nodes } = useContext(TreeContext);
  const { spaceStatus, occupancy, occupancyActiveDate } =
    (spaceId && nodes[spaceId]) || {};
  const showEarlyPreview =
    occupancy &&
    isZeroDate(occupancyActiveDate as string) &&
    spaceStatus === SpaceStatus.Inactive;

  if (showEarlyPreview) {
    return (
      <Typography variant="body2" color="warning.main">
        Calibration
      </Typography>
    );
  }
  if (status === ZoneStatus.Live) {
    return (
      <Typography variant="body2" color="success.main">
        Live
      </Typography>
    );
  }
  if (status === ZoneStatus.Inactive) {
    return (
      <Typography variant="body2" color="grey.500">
        Inactive
      </Typography>
    );
  }
  if (status === ZoneStatus.Setup) {
    return (
      <Typography variant="body2" color="warning">
        Inactive
      </Typography>
    );
  }
  if (status === ZoneStatus.Calibration) {
    return (
      <Typography variant="body2" color="warning.main">
        Calibration
      </Typography>
    );
  }
  if (status === ZoneStatus.Training) {
    return (
      <Typography variant="body2" color="grey.500">
        Training
      </Typography>
    );
  }
  if (status === ZoneStatus.Inreview) {
    return (
      <Typography variant="body2" color="grey.500">
        In Review
      </Typography>
    );
  }
  return null;
};

export default ZoneStatusIndicator;
