import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import {
  AnalyticsRoute,
  AppRoutes,
  getCurrentAnalyticsTabRoute,
  getCurrentFirstPath,
  getCurrentSpacesTabRoute,
  isCurrentAnalyticsTabRouteAvailable,
} from "./routing";
import { FetchPolicy } from "./network";
import TreeContext from "./TreeContext";
import { SpaceStatus } from "./interfaces";
import { getSpaceNodesByIdMap } from "common/trees";
import LoadingCircle from "./components/LoadingCircle";

const GetMySpaceTrees = gql`
  query GetMySpaceTrees {
    GetMySpaceTrees
  }
`;

interface Props {
  children: React.ReactNode;
}

const TreeProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery(GetMySpaceTrees, {
    fetchPolicy: FetchPolicy.NoCache,
  });

  const firstPath = getCurrentFirstPath(pathname);
  const splitPathname = pathname.split("/");

  const disableFiltrationSpaces =
    firstPath === AppRoutes.Analytics || firstPath === AppRoutes.LiveData;

  const lockNonLiveSpaces =
    firstPath === AppRoutes.Analytics ||
    firstPath === AppRoutes.LiveData ||
    pathname === "/";

  if (error) {
    throw error;
  }

  if (loading) {
    return <LoadingCircle />;
  }

  const parsedData = JSON.parse(data.GetMySpaceTrees);
  const trees = parsedData["tree"];
  const products = parsedData["products"];

  console.log({ trees });

  const hasNoSpaces = trees.length === 0;

  const nodes = getSpaceNodesByIdMap(trees);
  // TODO: have backend provide allSetup
  let allSetup = true;
  for (const id in nodes) {
    // Once one space is not setup, we can break out of the loop
    if (nodes[id].spaceStatus !== SpaceStatus.Setup) {
      allSetup = false;
      break;
    }
  }

  // put this here so the space tree component doesn't have to worry about where it exists
  const handleTreeNodeSelection = (id: string) => {
    if (hasNoSpaces) return;
    if (allSetup && lockNonLiveSpaces) return;
    if (firstPath === AppRoutes.Spaces) {
      const currentlySelectedTab = getCurrentSpacesTabRoute(splitPathname);
      navigate(`/${AppRoutes.Spaces}/${id}/${currentlySelectedTab}`);
    } else if (firstPath === AppRoutes.Analytics) {
      const node = nodes[id];
      const hasVisitors = node.visitors;
      const hasDwelltime = node.dwelltime;
      const hasOccupancy = node.occupancy;

      const currentAnalyticsTabRoute =
        getCurrentAnalyticsTabRoute(splitPathname);
      const currentTabToUse = isCurrentAnalyticsTabRouteAvailable(
        currentAnalyticsTabRoute,
        hasVisitors,
        hasDwelltime,
        hasOccupancy
      )
        ? currentAnalyticsTabRoute
        : AnalyticsRoute.Overview;
      navigate(`/${AppRoutes.Analytics}/${id}/${currentTabToUse}`);
    } else if (pathname === "/") {
      navigate(`/${AppRoutes.Analytics}/${id}`);
    } else {
      navigate(`/${firstPath}/${id}`);
    }
  };

  const treeContextValue = {
    hasNoSpaces,
    allSetup,
    disableFiltrationSpaces,
    loading,
    lockNonLiveSpaces,
    handleTreeNodeSelection,
    nodes,
    trees,
    products,
    rawTreeData: data.GetMySpaceTrees,
  };

  return (
    <TreeContext.Provider value={treeContextValue}>
      {children}
    </TreeContext.Provider>
  );
};

export default TreeProvider;
