import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import Container from "@mui/material/Container";
import Login from "./views/Login";
import FirstTimersForm, {
  InputData as FirstTimerFormInputData,
} from "./views/Login/FirstTimersForm";
import AuthContext from "./authContext";
import TrackerContext from "./TrackerContext";
import { OccuUser } from "./interfaces";
import { AppRoutes } from "./routing";
import { isEmailValid } from "./util";
import { getCurrentFirstPath } from "./routing";
import LoadingCircle from "./components/LoadingCircle";
import { setUserId } from "@snowplow/browser-tracker";

interface Props {
  children: React.ReactNode;
}

export interface AuthProviderState {
  cognitoUser: CognitoUser | null;
  isLoading: boolean;
  isAllZonesInactive: boolean;
  isInitialLoad: boolean;
  error: boolean;
  occuUser: OccuUser | null;
  newPasswordRequired: boolean;
  userForgotPassword: boolean;
  showForgotPasswordConfirm: boolean;
  invalidEmail: boolean;
}

// Regex for at least 8 characters, one uppercase and one digit
export const eightCharRegex = new RegExp("^(?=.{8,}$)");
export const oneUppercaseRegex = new RegExp("^(?=.*?[A-Z])");
export const oneDigitRegex = new RegExp("^(?=.*?[0-9])");

const GetMe = gql`
  query GetMe {
    GetMe {
      id
      fullName
      department
      title
      firstName
      lastName
      email
      role
      lastActive
      customerName
      cognitoCreateDate
    }
  }
`;

const RequiredNewUserMutation = gql`
  mutation RequiredNewUserMutation(
    $userId: Int!
    $firstNameInput: String!
    $lastNameInput: String!
    $departmentInput: String!
    $titleInput: String!
  ) {
    EditUserName(
      userId: $userId
      firstNameInput: $firstNameInput
      lastNameInput: $lastNameInput
    )
    EditUserDepartment(userId: $userId, departmentInput: $departmentInput)
    EditUserTitle(userId: $userId, titleInput: $titleInput)
  }
`;

const DEFAULT_AUTH_PROVIDER_STATE = {
  cognitoUser: null,
  occuUser: null,
  isLoading: true,
  isAllZonesInactive: true,
  isInitialLoad: true,
  invalidEmail: false,
  error: false,
  newPasswordRequired: false,
  showForgotPasswordConfirm: false,
  userForgotPassword: false,
};

const NEW_PASSWORD_CHALLENGE_NAME = "NEW_PASSWORD_REQUIRED";

const authContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  p: { xs: 0 },
};

const loginRoutes = new Set([
  AppRoutes.Login,
  AppRoutes.ResetPassword,
  AppRoutes.Register,
]);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { tracker } = useContext(TrackerContext);
  const [state, setState] = useState<AuthProviderState>(
    DEFAULT_AUTH_PROVIDER_STATE
  );
  const [getOccuUser] = useLazyQuery(GetMe, {
    fetchPolicy: "network-only",
  });
  const [mutateNewUser] = useMutation(RequiredNewUserMutation);

  const firstPath = getCurrentFirstPath(pathname);

  // when the page loads, we first see if `Auth` finds an already logged in user
  useEffect(() => {
    // if the user is trying to reset their password, do not try to get a user
    if (firstPath === AppRoutes.Register) {
      return;
    }

    if (firstPath === AppRoutes.ResetPassword) {
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
      });
      return;
    }

    (async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await handleLoggedInCognitoUser(cognitoUser);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (Auth as any)._storage.clear();
        // this happens when Auth can't find an already logged in user
        setState({
          ...state,
          isLoading: false,
          isInitialLoad: false,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (firstPath === AppRoutes.Register) {
      const user = searchParams.get("user");
      const code = searchParams.get("code");

      signin(user || "", code || "");
      return;
    }
  }, [firstPath]);

  useEffect(() => {
    if (
      loginRoutes.has(firstPath as AppRoutes) &&
      cognitoUser != null &&
      state.occuUser &&
      state.occuUser.firstName &&
      state.occuUser.lastName
    ) {
      navigate("/", { replace: true });
    }
  }, [firstPath, state.cognitoUser, state.occuUser]);

  useEffect(() => {
    if (occuUser?.id && tracker) {
      setUserId(occuUser.id);
    }
  }, [state.occuUser, tracker]);

  const refetchLoggedInUser = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      setState({
        ...state,
        isLoading: false,
        occuUser: { ...user },
      });
    } catch {
      setState({
        ...state,
        isLoading: false,
        error: true,
      });
    }
  };

  const handleLoggedInCognitoUser = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cognitoUser: any
  ) => {
    if (cognitoUser.challengeName === NEW_PASSWORD_CHALLENGE_NAME) {
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
        error: false,
        newPasswordRequired: true,
        cognitoUser,
      });
      return;
    }
    try {
      if (state.isLoading !== true) {
        setState({
          ...state,
          isLoading: true,
        });
      }
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      console.log({ user });

      setState({
        ...state,
        error: false,
        isLoading: false,
        isInitialLoad: false,
        occuUser: { ...user },
        cognitoUser,
      });
    } catch (e) {
      setState({
        ...state,
        cognitoUser,
        isLoading: false,
        isInitialLoad: false,
        error: true,
      });
    }
  };

  const handleFirstTimerFormSubmit = async ({
    firstName,
    lastName,
    department,
    title,
  }: FirstTimerFormInputData) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });
      await mutateNewUser({
        variables: {
          firstNameInput: firstName.trim(),
          lastNameInput: lastName.trim(),
          departmentInput: department.trim(),
          titleInput: title.trim(),
          userId: (state.occuUser as OccuUser).id,
        },
      });
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      setState({
        ...state,
        isLoading: false,
        occuUser: { ...user },
      });
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        error: true,
      });
    }
  };

  const sendResetPasswordEmail = async (email: string) => {
    try {
      setState({
        ...state,
        isLoading: true,
        error: false,
      });
      await Auth.forgotPassword(email.toLocaleLowerCase());
      setState({
        ...state,
        isLoading: false,
        userForgotPassword: false,
        showForgotPasswordConfirm: true,
      });
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        userForgotPassword: false,
        showForgotPasswordConfirm: true,
      });
    }
  };

  const resetErrorMessage = () => {
    setState({
      ...state,
      error: false,
      invalidEmail: false,
    });
  };

  // submits a new user password via reset password flow
  const resetPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    try {
      setState({
        ...state,
        isLoading: true,
        error: false,
      });
      await Auth.forgotPasswordSubmit(
        username.toLocaleLowerCase(),
        code,
        newPassword
      );
      // This was added during testing of cognito user pool migration
      // Without it, after a migrating user reset their password they were unable to login
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (Auth as any)._storage.clear();
      // Sign in users resetting password
      // First time Users get sent to FirstTimers form
      if (!state.newPasswordRequired) {
        signin(username.toLocaleLowerCase(), newPassword);
      } else {
        setState({
          ...state,
          isLoading: false,
          error: false,
        });
      }
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        error: true,
      });
    }
  };

  const signin = async (username: string, password: string) => {
    const lowercaseUsername = username.toLocaleLowerCase();
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const cognitoUser = await Auth.signIn(lowercaseUsername, password);
      await handleLoggedInCognitoUser(cognitoUser);
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
        error: true,
      });
    }
  };

  const signout = async () => {
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const result = await Auth.signOut();
      setState({
        ...state,
        newPasswordRequired: false,
        userForgotPassword: false,
        isLoading: false,
        occuUser: null,
        cognitoUser: null,
      });
      navigate("/login", { replace: true });
      return result;
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        error: true,
      });
      return false;
    }
  };

  const completeNewPassword = async (newPassword: string) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });

      const cognitoUser = await Auth.completeNewPassword(
        state.cognitoUser,
        newPassword
      );
      await handleLoggedInCognitoUser(cognitoUser);
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        error: true,
      });
    }
  };

  const validateEmail = (email: string) => {
    if (!email) {
      return;
    }
    const validEmail = isEmailValid(email);
    if (!validEmail) {
      setState({
        ...state,
        invalidEmail: true,
      });
    }
  };

  const handleUserForgotPassword = () => {
    setState({
      ...state,
      userForgotPassword: true,
      error: false,
    });
  };

  const handleBackToLoginClick = () => {
    setState({
      ...state,
      userForgotPassword: false,
      error: false,
    });
  };

  const {
    cognitoUser,
    error,
    invalidEmail,
    isLoading,
    isAllZonesInactive,
    newPasswordRequired,
    occuUser,
    userForgotPassword,
    showForgotPasswordConfirm,
  } = state;

  const authContextValue = {
    cognitoUser,
    completeNewPassword,
    error,
    invalidEmail,
    isLoading,
    isAllZonesInactive,
    newPasswordRequired,
    showForgotPasswordConfirm,
    occuUser,
    refetchLoggedInUser,
    resetErrorMessage,
    resetPasswordSubmit,
    sendResetPasswordEmail,
    signin,
    signout,
    validateEmail,
    userForgotPassword,
  };

  if (
    state.isInitialLoad ||
    (firstPath === AppRoutes.Register && !error && !isLoading && !cognitoUser)
  ) {
    return (
      <AuthContext.Provider value={authContextValue}>
        <LoadingCircle />
      </AuthContext.Provider>
    );
  }

  if (occuUser && (!occuUser.firstName || !occuUser.lastName)) {
    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <FirstTimersForm onSubmit={handleFirstTimerFormSubmit} />
        </Container>
      </AuthContext.Provider>
    );
  }

  if (cognitoUser == null || occuUser === null) {
    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <Login
            onUserForgotPassword={handleUserForgotPassword}
            onBackToLoginClick={handleBackToLoginClick}
          />
        </Container>
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
